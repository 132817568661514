/**
 * Hamburger Button.
 * @format
 */

button.hamburger {
  border: 0;
  width: 4rem;
  height: 4rem;
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 0;
  background-color: $white;
  transition: $leave-screen $ease-out-curve;
  transform: translate3d(0, 0, 0);

  @media (max-width: $screen-lg) {
    margin: 1.8rem 0;
  }

  @media (max-width: $screen-md) {
    margin: 0.8rem 0;
  }

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 0.3rem;
    width: 2.5rem;
    border-radius: 2rem;
    left: 0.8rem;
    background-color: $blue;
    transition: $leave-screen $ease-out-curve;
  }

  &::before {
    top: 1.3rem;
  }

  &::after {
    bottom: 1rem;
  }

  .bar {
    display: block;
    background-color: $blue;
    height: 0.3rem;
    width: 2.5rem;
    position: relative;
    border-radius: 2rem;
    margin: 0 0.8rem;
    opacity: 1;
    transform: translate3d(0, 0.1rem, 0);
    transition: $leave-screen $ease-out-curve;
  }

  &.open {
    transform: translate3d(0, 0.2rem, 0);

    &::before {
      top: 1.2rem;
      transform: rotate(-45deg) translate3d(-0.2rem, 0.5rem, 0);
      width: 2.4rem;
      height: 0.4rem;
    }

    &::after {
      bottom: 1.1rem;
      transform: rotate(45deg) translate3d(-0.3rem, -0.7rem, 0);
      width: 2.4rem;
      height: 0.4rem;
    }

    .bar {
      transform: translate3d(1.5rem, 0, 0);
      opacity: 0;
    }
  }
}
