/** @format */

.top-header-bar {
  button.language-select-button {
    border: 0;
    height: 100%;
    background: none;
    line-height: 3.2rem;
    padding: 0 1.2rem;
    background: none;
    z-index: 1;
    position: relative;

    img {
      height: 1.4rem;
      width: 1.4rem;
      margin: 0;
      margin-right: 0.6rem;
      vertical-align: text-top;
    }
  }
}

.responsive-navigation {
  button.language-select-button {
    border: 0;
    background: none;
    line-height: 2rem;
    padding: 0;
    background-color: #fff;
    height: 4rem;
    margin: 2rem 3.2rem;
    font-size: 1.2rem;

    @media (max-width: $screen-md) {
      margin: 0.8rem 2rem;
    }

    @media (max-width: $screen-sm) {
      margin: 0.8rem 1rem;

      span {
        display: none;
      }
    }

    img {
      height: 1.6rem;
      width: 1.6rem;
      margin: 0;
      margin-right: 0.6rem;
      vertical-align: middle;
      margin-bottom: 0.2rem;
    }
  }
}
