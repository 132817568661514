/** @format */

.submission-response {
  opacity: 0;
  display: none;
  text-align: center;
}

.form-submitted {
  &.transitioning {
    .submission-response {
      opacity: 0;
      display: block;
    }

    h2,
    p {
      transform: translate3d(0, 1.6rem, 0);
      opacity: 0;
    }
  }

  .submission-response {
    transition: $enter-screen $ease-out-curve $enter-screen;
    opacity: 1;
    display: block;
  }

  h2,
  p {
    transform: translate3d(0, 0, 0);
    transition: $enter-screen $ease-out-curve 250ms;
    opacity: 1;
  }

  p {
    transition: $enter-screen $ease-out-curve 300ms;
  }
}
