/**
 * Featured Menu Item
 * @format
 */

.featured-menu-item {
  margin-bottom: 2rem;

  .featured-menu-image {
    display: block;
    width: 100%;
    padding-bottom: 51%;
    background-color: $grey-light;
    margin: 0.6rem 0 1.8rem;
    overflow: hidden;
    position: relative;

    img {
      padding: 0;
      min-height: 100%;
      min-width: 100%;
      position: absolute;
      margin: auto;
      top: -100%;
      left: -100%;
      bottom: -100%;
      right: -100%;
    }
  }

  .featured-menu-title,
  .featured-menu-blurb,
  .featured-menu-link {
    font-family: $font-semibold;
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: -0.011rem;
    color: $black;
    padding: 0;
    margin: 0;
  }

  .featured-menu-blurb {
    font-family: $font-regular;
    color: $grey-dark;
    margin: 1.2rem 0;
  }

  .featured-link {
    font-family: $font-semibold;
    color: $hover;
    display: block;

    &::after {
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      content: '\f105';
      margin-left: 0.6rem;
    }
  }
}

.responsive-navigation-container .featured-menu-item {
  width: 30rem;
  padding: 3rem 0;
  border-top: solid 1px $grey-blue;
  margin-top: 3rem;
}
