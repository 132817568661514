/**
 * Responsive Menu.
 *
 * @format
 */

@import './hamburger-button/hamburger-button';
@import './submenu/responsive-submenu';

nav.responsive-navigation {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.responsive-navigation-wrapper {
  display: none;
  height: calc(100vh - 8rem);
  position: fixed;
  top: 8rem;
  right: 0;
  width: calc(50% - 12rem);
  background-color: $white;
  z-index: 105;
  overflow: hidden;
  animation: responsive-menu-slide-out;
  animation-timing-function: $ease-out-curve;
  animation-duration: $enter-screen;

  &.open {
    display: block;

    .responsive-navigation-container {
      .mobile-search-box {
        display: block;
        animation: mobile-fade-in;
        animation-timing-function: $ease-out-curve;
        animation-duration: 500ms;
      }

      > ul > li {
        display: block;
        animation: mobile-fade-in;
        animation-timing-function: $ease-out-curve;
        animation-duration: 550ms;
      }

      .auxillary-navigation li {
        display: block;
        animation: mobile-fade-in;
        animation-timing-function: $ease-out-curve;
        animation-duration: 600ms;
      }
    }

    @media (max-width: $screen-sm) {
      right: auto;
      left: 0;
    }
  }

  &.closing {
    display: block;
    opacity: 0;
    animation: responsive-menu-slide-in;
    animation-timing-function: $standard-curve;
    animation-duration: 400ms;

    .responsive-navigation-container {
      .mobile-search-box,
      > ul > li,
      .auxillary-navigation li {
        display: block;
        opacity: 0;
        animation: mobile-fade-out;
        animation-timing-function: $ease-out-curve;
        animation-duration: $leave-screen;
      }
    }
  }

  @media (max-width: $screen-md) {
    width: calc(50% + -1.5rem);
    top: 5.6rem;
    height: calc(100vh - 5.6rem);
  }

  @media (max-width: $screen-sm) {
    width: 100%;
    top: 5.6rem;
    height: calc(100vh - 5.6rem);
  }
}

.responsive-navigation-outter-container {
  width: 34rem;
  height: 100%;
  position: relative;
  overflow: hidden;

  @media (max-width: $screen-sm) {
    width: 100%;
  }
}

.responsive-navigation-container {
  height: 100%;
  width: calc(100% + 3.5rem);
  overflow-y: scroll;
  position: absolute;
  top: 0;
  left: 0;
  padding: 2rem 3.5rem 0 4rem;

  @media (max-width: $screen-sm) {
    padding: 2rem 7.5rem 0 3.5rem;
  }
}

.background-overlay {
  display: none;
  min-height: calc(100vh - 8rem);
  min-width: 100vw;
  position: fixed;
  top: 8rem;
  left: 0;
  background-color: $black;
  opacity: 0.5;
  z-index: 100;
  animation: fade-in-5;
  animation-timing-function: $standard-curve;
  animation-duration: 600ms;

  &.open {
    display: block;
  }

  &.closing {
    display: block;
    opacity: 0;
    animation: fade-out-5;
    animation-timing-function: $standard-curve;
    animation-duration: 400ms;
  }

  @media (max-width: $screen-md) {
    top: 5.6rem;
    height: calc(100vh - 5.6rem);
  }
}

.adminimal-admin-toolbar .top {
  .background-overlay,
  .responsive-navigation-wrapper {
    top: 8rem;
    height: calc(100vh - 8rem);

    @media (max-width: $screen-md) {
      top: 5.6rem;
      height: calc(100vh - 5.6rem);
    }
  }

  &.closing {
    .background-overlay,
    .responsive-navigation-wrapper {
      top: 11.9rem;
      height: calc(100vh - 11.9rem);

      @media (max-width: $screen-md) {
        top: 9.5rem;
        height: calc(100vh - 9.5rem);
      }
    }
  }
}

.adminimal-admin-toolbar {
  .responsive-navigation-outter-container {
    height: calc(100% - 4rem);
  }

  &.toolbar-tray-open {
    .responsive-navigation-outter-container {
      height: calc(100% - 8rem);
    }
  }
}

.adminimal-admin-toolbar.toolbar-tray-open .top {
  .background-overlay,
  .responsive-navigation-wrapper {
    top: 8rem;
    height: calc(100vh - 8rem);
  }

  &.closing {
    .background-overlay,
    .responsive-navigation-wrapper {
      top: 15.9rem;
      height: calc(100vh - 15.9rem);
    }
  }
}

.adminimal-admin-toolbar .background-overlay,
.adminimal-admin-toolbar .responsive-navigation-wrapper {
  top: 8rem;
  height: calc(100vh - 8rem);

  @media (max-width: $screen-md) {
    top: 5.6rem;
    height: calc(100vh - 5.6rem);
  }
}

.adminimal-admin-toolbar.toolbar-tray-open .background-overlay,
.adminimal-admin-toolbar.toolbar-tray-open .responsive-navigation-wrapper {
  top: 8rem;
  height: calc(100vh - 8rem);

  // &.closing {
  //   top: 8rem;
  // }

  @media (max-width: $screen-md) {
    top: 7.9rem;
    height: calc(100vh - 7.9rem);
  }

  @media (max-width: $screen-sm) {
    top: 9.5rem;
    height: calc(100vh - 9.5rem);
  }
}
