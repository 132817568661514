/** @format */

.mobile-search-box {
  position: relative;
  width: 100%;
  display: none;

  input {
    margin: 0 0 2.4rem;
  }

  img.search-icon {
    position: absolute;
    line-height: 4rem;
    top: 1.6rem;
    right: 1.2rem;
    margin: 0;
    width: 1.8rem;
  }
}
