/**
 * Default Variables.
 *
 * Modify this file to provide default Bootstrap Framework variables. The
 * Bootstrap Framework will not override any variables defined here because it
 * uses the `` flag which will only set their default if not already
 * defined here.
 *
 * You can copy existing variables directly from the following file:
 * ./varian/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss
 */


//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
$screen-xs:                  376px;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  1024px;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1440px;


//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-base:              #dadfe1;
$gray-light:             #e9edef;
$gray-lighter:           #f0f2f3;
$gray-darker:            #53565a;
$gray-dark:              #99a4ae;
$gray:                   #a6bbc3;

$brand-primary:         #00a9e0;
$brand-success:         #1ac05d;
$brand-info:            #5bc0de;
$brand-warning:         #f0ad4e;
$brand-danger:          #ff0402;


//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg:               #fff;
//** Global text color on `<body>`.
$text-color:            #000; //The value is a custom variable.

//** Global textual link color.
$link-color:            $varian-blue;
//** Link hover color set via `darken()` function.
$link-hover-color:      darken($link-color, 15%);
//** Link hover decoration.
$link-hover-decoration: underline;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  "Mark W02 Regular", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif:       Georgia, "Times New Roman", Times, serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-base:        $font-family-sans-serif !default;

$font-size-base:          14px;
$font-size-large:         ceil(($font-size-base * 1.25)); // ~18px
$font-size-small:         ceil(($font-size-base * .85)); // ~12px

$font-size-h1:            ceil(($font-size-base * 4.571)); // ~64px
$font-size-h2:            ceil(($font-size-base * 3.428)); // ~48px
$font-size-h3:            ceil(($font-size-base * 2.857)); // ~40px
$font-size-h4:            ceil(($font-size-base * 2.285)); // ~32px
$font-size-h5:            ceil(($font-size-base * 1.714)); // ~24px
$font-size-h6:            ceil(($font-size-base * 1.428)); // ~20px

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.428571429 !default; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)) !default; // ~20px

//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-font-weight:                normal;

$btn-default-color:              #333;
$btn-default-bg:                 #fff;
$btn-default-border:             #ccc;

$btn-primary-color:              $white;
$btn-primary-bg:                 $varian-blue;
$btn-primary-border:             transparent;

$btn-success-color:              #fff;
$btn-success-bg:                 $brand-success;
$btn-success-border:             darken($btn-success-bg, 5%);

$btn-info-color:                 #fff;
$btn-info-bg:                    $brand-info ;
$btn-info-border:                darken($btn-info-bg, 5%);

$btn-warning-color:              #fff;
$btn-warning-bg:                 $brand-warning;
$btn-warning-border:             darken($btn-warning-bg, 5%);

$btn-danger-color:               #fff;
$btn-danger-bg:                  $brand-danger;
$btn-danger-border:              darken($btn-danger-bg, 5%);

$btn-link-disabled-color:        $gray-base;

$btn-border-radius-base:         2rem;
$btn-border-radius-large:        2rem;
$btn-border-radius-small:        2rem;