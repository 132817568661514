/** @format */

/**
 * Top Header Bar.
 */
.top-header-bar {
  background-color: #f0f2f3;
  height: 3.2rem;
  font-family: $font-regular;
  color: $black;
  font-size: 1.2rem;
  z-index: 105;
  position: relative;

  ul {
    list-style-type: none;
    display: flex;
    padding: 0;
    margin: 0;
    justify-content: flex-end;

    li {
      margin: 0;
      position: relative;

      &.language-selector-aux-link {
        margin-right: 0;

        // &::before {
        //   display: none;
        // }

        a {
          padding-right: 0;
        }
      }

      &::before {
        content: '';
        height: 100%;
        width: 100%;
        display: block;
        background-color: $grey-light;
        position: absolute;
        bottom: 0;
        left: 0;
        transform-origin: bottom left;
        transform: scaleY(0);
        opacity: 0;
        transition: $enter-screen $ease-out-curve;
      }

      &:hover {
        &::before {
          opacity: 1;
          transform: scaleY(1);
        }

        .language-select {
          display: block;
          animation: slide-out;
          animation-duration: $shorter-duration;
          animation-timing-function: $standard-curve;
        }
      }
    }
  }

  a {
    color: $black;
    line-height: 3.2rem;
    display: inline-block;
    padding: 0 1.2rem;
    transition: $shorter-duration $sharper-curve;
    cursor: pointer;
    z-index: 1;
    position: relative;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }
  }
}

/**
 * Main Header Bar.
 */
.main-header-bar {
  background-color: $white;
  height: 8rem;
  z-index: 100;
  position: relative;
  width: 100%;
  transform: rotate(0deg);

  &.menu-open {
    position: relative;

    @media (max-width: $screen-lg) {
      position: fixed;
    }
  }

  img {
    width: 14rem;
    margin: 2.7rem 0;

    &.search-icon {
      width: 2rem;
      vertical-align: middle;
      transform: translate3d(0, -0.2rem, 0);
      margin-right: 1rem;
    }
  }

  .navigation-container {
    display: flex;
  }

  @media (max-width: $max-width-lg) {
    transform: translate3d(0, -100%, 0);
    position: fixed;
    top: 0;
    transition: $enter-screen $standard-curve;

    img {
      width: 10rem;
      margin: 3rem 0;
    }

    &.show,
    &.menu-open {
      transform: translate3d(0, 0, 0);
      box-shadow: $box-shadow;
    }

    &.top {
      position: relative;
      top: 0;
      transform: none;
      transition: none;
      box-shadow: none;

      &.menu-open {
        transform: translate3d(0, 0, 0);
        position: fixed;
      }
    }
  }

  @media (max-width: $screen-md) {
    height: 5.6rem;

    img {
      width: 8.6rem;
      margin: 2rem 0;
    }
  }
}

header#nav-header {
  height: 11.2rem;

  @media (max-width: $max-width-lg) {
    height: 7.6rem;
  }

  @media (max-width: $screen-md) {
    height: 5.6rem;
  }
}

@media (max-width: $max-width-lg) {
  #varian-header {
    z-index: 100;
    width: 100%;
    background-color: #fff;
  }

  .adminimal-admin-toolbar {
    .main-header-bar {
      top: 3.9rem;

      &.top {
        top: 0;
      }

      &.top.menu-open {
        top: 3.9rem;
      }
    }

    &.toolbar-tray-open {
      .main-header-bar {
        top: 7.9rem;

        &.top {
          top: 0;
        }

        &.top.menu-open {
          top: 7.9rem;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .adminimal-admin-toolbar {
    .main-header-bar {
      top: 0;
    }
  }
}

.external-header-spacer {
  display: none;
  height: 8rem;

  &.show {
    display: block;
  }

  @media (max-width: $max-width-md) {
    height: 5.6rem;
  }
}
