/** @format */

.responsive-navigation-container {
  a:hover {
    text-decoration: none;
  }

  .submenu {
    padding: 2.4rem 1.5rem 0 4rem;
    display: none;
    width: 30rem;
    min-height: 100%;
  }

  .parent-31.has-submenu,
  .parent-32.has-submenu,
  .parent-30.has-submenu .has-submenu {
    padding: 1.2rem 0;
  }

  .parent-31,
  .parent-32,
  .parent-30.has-submenu .has-submenu {
    &:last-child {
      padding-bottom: 0;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;

    a {
      font-family: $font-regular;
      letter-spacing: -0.011rem;
      font-size: 1.6rem;
      line-height: 1.5;
      color: $black;
      display: block;
      margin: 1.2rem 0;
    }

    li {
      &.has-submenu > a {
        font-family: $font-semibold;
      }
    }
  }

  > ul {
    height: auto;

    > li {
      display: none;
    }
  }

  li.active {
    min-height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 110;
    background-color: $white;
    padding-bottom: 4rem;

    > button {
      display: none;
    }

    > .submenu {
      display: block;
    }
  }

  button {
    font-family: $font-semibold;
    letter-spacing: -0.02rem;
    font-size: 1.6rem;
    line-height: 1.5;
    background: none;
    color: $black;
    border: 0;
    margin: 1rem 0;
    padding: 0;

    &::after {
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      content: '\f105';
      margin-left: 0.6rem;
      transform: translate3d(0, 0, 0);
      transition: $leave-screen $ease-out-curve;
      display: inline-block;
    }

    &:hover {
      &::after {
        transform: translate3d(0.6rem, 0, 0);
      }
    }
  }

  button.back-button {
    margin: 0;

    &::before {
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      content: '\f104';
      margin-right: 0.8rem;
      transform: translate3d(0, 0, 0);
      transition: $leave-screen $ease-out-curve;
      display: inline-block;
    }

    &:hover {
      &::before {
        transform: translate3d(-0.4rem, 0, 0);
      }
    }

    &::after {
      display: none;
    }
  }

  .submenu-title {
    font-size: 2.4rem;
    font-family: $font-semibold;
    letter-spacing: -0.04rem;
    color: $black;
    margin: 3.6rem 0 1.8rem;
  }

  .auxillary-navigation {
    border-top: solid 1px $grey-blue;
    width: 100%;
    margin-top: 4rem;
    padding-top: 4rem;

    li {
      display: none;
      margin: 1.2rem 0;
    }
  }

  li.bold a,
  li.contact-form-button button {
    font-family: $font-semibold;
    letter-spacing: -0.011rem;
    font-size: 1.6rem;
    line-height: 1.5;
    color: $black;
    display: block;
    margin: 1.2rem 0;
    padding: 0;

    &::after {
      display: none;
      letter-spacing: -0.011rem;
    }
  }
}

.responsive-navigation.transitioning-forward,
.responsive-navigation.transitioning-backward {
  .has-submenu.current.active {
    .submenu {
      animation: responsive-menu-navigate-forward;
      animation-duration: $longer-duration;
      animation-timing-function: $ease-out-curve;
    }
  }
}

.responsive-navigation.transitioning-backward {
  .has-submenu.current.active {
    .submenu {
      animation: responsive-menu-navigate-backward;
      animation-duration: $longer-duration;
      animation-timing-function: $ease-out-curve;
    }
  }
}

.responsive-navigation.transitioning-backward.main-active {
  .responsive-navigation-container {
    animation: responsive-menu-navigate-backward;
    animation-duration: $longer-duration;
    animation-timing-function: $ease-out-curve;
  }
}
