/** @format */

.breadcrumb {
  background-color: transparent;
  border-top: 0.1rem solid $gray-base;
  border-radius: 0;
  padding: 1.4rem 0;
  margin: 0 auto;
  width: 100%;
  font-size: 1.3rem;
  line-height: 1.5;
  font-family: $font-regular;

  @media (min-width: $screen-sm) {
    width: 75rem;
  }

  @media (min-width: $screen-md) {
    width: 94rem;
    margin-top: 0.4rem;
  }

  @media (min-width: $screen-lg) {
    width: 114rem;
    margin-top: 0;
  }

  li + li:before {
    color: $black;
    content: '\f053';
    background-image: none;
    position: relative;
    display: inline-block;
    font-family: $font-awesome;
    border: 0;
    font-size: 1.1rem;

    @media screen and (min-width: $screen-sm) {
      content: '';
      background-image: none;
      width: 0.1rem;
      height: 1rem;
      border-left: solid 0.1rem $black;
      margin: 0 0.6rem;
      padding: 0;
    }
  }

  li {
    @media screen and (max-width: $max-width-sm) {
      display: none;

      &:last-child {
        display: inline-block;
      }
    }
  }

  .active {
    color: $black;
  }
}
