/**
 * Button Styles.
 *
 * @format
 */
.btn {
  white-space: normal;
}

.more-link a,
.btn-submit,
.btn-primary,
.btn-primary-reversed,
.btn-secondary,
.btn-secondary-reversed {
  padding: 0.5rem 4rem;
  line-height: 2rem;
  border-radius: 20rem;
  font-size: 1.6rem;
  background-color: $varian-blue;
  color: $white;
  letter-spacing: -0.011rem;
  transition: $enter-screen $ease-out-curve;
  font-family: $font-semibold;
  border: 0.2rem solid $varian-blue;
  outline: none;
  box-shadow: 0 0 0 0.2rem $varian-blue;

  &:hover {
    text-decoration: none;
    background-color: $button-hover;
    border: 0.2rem solid $button-hover;
    box-shadow: 0 0 0 0.2rem $button-hover;
  }

  &:disabled,
  &.disabled {
    background-color: $disabled-color;
    border: 0.2rem solid $disabled-color;
    box-shadow: 0 0 0 0.2rem $disabled-color;
    pointer-events: none;
    cursor: not-allowed;
  }
}

.btn-submit:focus,
.more-link a:focus,
.more-link a:active,
.blue .more-link a:focus,
.blue .more-link a:active,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary-reversed:focus,
.btn-secondary-reversed:active,
.btn:focus,
.btn:active {
  background-color: $varian-blue;
  color: $white;
  border: 0.2rem solid $white;
  outline: none;
  box-shadow: 0 0 0 0.2rem $varian-blue;
  text-decoration: none;
}

.blue .more-link a,
.background-blue .more-link a,
.blue .btn-primary,
.background-blue .btn-primary,
.btn-primary-reversed {
  background-color: $white;
  color: $varian-blue;
  border: 0.2rem solid $white;
  box-shadow: 0 0 0 0.2rem $white;

  &:hover {
    background-color: $secondary-button-hover;
    border: 0.2rem solid $secondary-button-hover;
    color: $varian-blue;
    box-shadow: 0 0 0 0.2rem $secondary-button-hover;
  }

  &:disabled,
  &.disabled {
    background-color: $disabled-reverse-color;
    border: 0.2rem solid $disabled-reverse-color;
    box-shadow: 0 0 0 0.2rem $disabled-reverse-color;
    pointer-events: none;
    cursor: not-allowed;
  }
}

.btn-secondary {
  background-color: transparent;
  color: $varian-blue;
  border: 0.2rem solid transparent;

  &:hover {
    background-color: $secondary-button-hover;
    border: 0.2rem solid $secondary-button-hover;
    color: $varian-blue;
  }

  &:disabled,
  &.disabled {
    background-color: $white;
    border: 0.2rem solid $white;
    box-shadow: 0 0 0 0.2rem $disabled-color;
    color: $disabled-color;
    pointer-events: none;
    cursor: not-allowed;
  }
}

.btn-secondary-reversed {
  background: transparent;
  background-color: transparent;
  border: 0.2rem solid transparent;
  color: $white;
  box-shadow: 0 0 0 0.2rem $white;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
    border: 0.2rem solid transparent;
    box-shadow: 0 0 0 0.2rem $white;
    color: $white;
  }

  &:disabled,
  &.disabled {
    background-color: transparent;
    border: 0.2rem solid transparent;
    box-shadow: 0 0 0 0.2rem $disabled-reverse-color;
    color: $disabled-reverse-color;
    pointer-events: none;
    cursor: not-allowed;
  }
}

.btn-link {
  color: $varian-blue;
  font-size: $font-size-body-1;
  line-height: $line-height-body-1;
}

.featured-link a,
a.featured-link {
  letter-spacing: -0.02rem;
  font-size: 1.6rem;
  font-family: $font-semibold;
  line-height: 1.5;
  color: $hover;

  &::after {
    font-family: $font-awesome;
    font-weight: 900;
    content: '\f105';
    margin-left: 0.6rem;
    transform: translate3d(0, 0, 0);
    transition: $leave-screen $ease-out-curve;
    display: inline-block;
  }

  &:hover {
    text-decoration: none;

    &::after {
      transform: translate3d(0.6rem, 0, 0);
    }
  }
}
