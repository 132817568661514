/**
 * Search Dropdown.
 *
 * @format
 */

nav.primary-navigation > ul li .search-dropdown {
  display: none;
  background-color: $white;
  position: fixed;
  left: 0;
  width: 100%;
  padding: 3rem;
  border: 0.1rem solid #dadfe1;
  margin: 0;

  h2 {
    font-family: $font-semibold;
    color: $black;
    font-size: 2rem;
    letter-spacing: -0.033rem;
    line-height: 1.25;
    margin: 0;
  }

  h3 {
    font-family: $font-regular;
    color: $black;
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: -0.011;
    margin: 0;
  }

  .search-icon {
    position: absolute;
    line-height: 4rem;
    top: 3.2rem;
    right: 1rem;
    margin: 0;
  }

  .search-container {
    position: relative;

    .search-results {
      animation: fade-in;
      animation-duration: $enter-screen;
      animation-timing-function: $ease-out-curve;
    }

    .search-box {
      position: relative;
      width: 90%;

      .fa-search {
        color: $grey-medium;
        position: absolute;
        line-height: 4rem;
        top: 2rem;
        right: 0.6rem;
        font-size: 1.8rem;
      }

      .fa-spinner {
        position: absolute;
        line-height: 4rem;
        top: 2rem;
        right: -3rem;
        font-size: 1.8rem;
        opacity: 0;
        color: $hover;
        transition: $enter-screen $ease-out-curve;

        &.loading {
          opacity: 1;
          animation: loading-spinner;
          animation-duration: 1s;
          animation-timing-function: linear;
          animation-iteration-count: infinite;
        }
      }
    }
  }

  .popular-search-terms {
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        margin: 1.8rem 0;
        line-height: 1.5;
        font-size: 1.6rem;

        a {
          color: $hover;
        }

        &::before {
          display: none;
        }
      }
    }
  }

  .search-results {
    width: 90%;

    .highlight {
      font-family: $font-bold;
    }

    .view-more-results {
      line-height: 1.5;
      margin: 4.8rem 0 2rem;

      a {
        color: $accent-blue;
        letter-spacing: -0.023rem;
        font-size: 1.8rem;
      }
    }

    h2 {
      margin: 4rem 0 0;
    }

    > ul {
      padding-left: 0;

      > li {
        list-style-type: none;
        margin: 3.2rem 0;

        a,
        p {
          font-size: 1.6rem;
          line-height: 1.5;
          display: block;
          margin: 0.8rem 0;
        }

        a {
          color: $accent-blue;
          font-family: $font-semibold;
        }

        &::before,
        &::after {
          display: none;
        }

        &.no-results {
          margin: 0;
        }
      }
    }
  }

  .container {
    display: none;
  }
}
