/**
 * Contact Information.
 *
 * @format
 */

.contact-info-nav li {
  font-weight: 500;
  font-size: $line-height-bold;
  margin: 2.5rem 0;
  animation: fade-in;
  animation-duration: $enter-screen;
  animation-timing-function: $standard-curve;

  a,
  button {
    font-weight: 500;
    font-family: $font-semibold;
  }

  button {
    background: none;
    border: 0;
    padding: 0;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: $screen-lg) {
    font-size: 1.6rem;
    letter-spacing: -0.011rem;
    margin: 2rem 0;
  }

  &:first-child {
    margin-top: 0;
  }
}
