/**
 * Standard Menu Dropdown.
 *
 * @format
 */

nav.primary-navigation > ul li .mega-menu-dropdown {
  display: none;
  background-color: $white;
  position: fixed;
  left: 0;
  width: 100%;
  padding: 3rem;
  border: 0.1rem solid #dadfe1;
  margin: 0;

  .container {
    display: none;
  }
}
