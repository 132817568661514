/** @format */

nav.primary-navigation li.contact-form-button button {
  background: none;
  border: 0;
  padding: 0.6rem 0;
}

#contact-form-footer,
#contact-form-header {
  max-width: 110rem;

  .contact-info {
    font-size: 2rem;
    line-height: 2.5rem;
    letter-spacing: -0.033rem;
    font-family: $font-semibold;
    font-weight: normal;
    margin: 0 4rem;

    @media screen and (max-width: $max-width-md) {
      font-size: 1.8rem;
      letter-spacing: -0.023rem;
    }

    @media screen and (max-width: $max-width-sm) {
      margin: 8rem 0 4rem;
    }

    .or,
    .email-us,
    .call-us {
      display: block;
      margin: 2.4rem 0;
    }

    .or {
      margin-top: 3.6rem;
    }

    .call-us {
      margin-bottom: 5.2rem;
    }

    .phone,
    .email {
      display: block;
      font-size: 2.4rem;
      letter-spacing: -0.04rem;
      margin: 0.8rem 0;

      @media screen and (max-width: $max-width-md) {
        font-size: 2rem;
        letter-spacing: -0.033rem;
      }
    }
  }
}
