/**
 * Legal Navigation.
 *
 * @format
 */

.legal-footer-wrapper {
  background-color: #f0f2f3;
  min-height: 4rem;

  @media (max-width: $screen-lg) {
    min-height: 7rem;
  }

  @media (max-width: 767px) {
    min-height: 20rem;
  }
}

ul.footer-legal-nav {
  margin: 0;
  padding: 0;
  animation: fade-in;
  animation-duration: $enter-screen;
  animation-timing-function: $standard-curve;

  li {
    display: block;
    margin: 1rem;

    a {
      color: $black;
      font-size: $font-size-body-2;
      letter-spacing: -0.011rem;
      line-height: 4rem;
    }
  }

  @media (min-width: $screen-sm) {
    li {
      display: inline-block;
      margin: 0 2.8rem;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  @media (max-width: $screen-lg) {
    min-height: 7rem;

    li {
      a {
        line-height: 7rem;
      }
    }
  }

  @media (max-width: 767px) {
    li {
      display: block;
      margin: 2rem 0;

      a {
        line-height: 1.5;
      }
    }
  }
}

/**
 * TrustE
 */
.trust-e {
  padding: 1rem 0;
  min-height: 6.4rem;
}

#truste-consent-track {
  position: fixed;
  z-index: 500;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #394b59 !important;

  .truste-button2 {
    background: none;
    color: #e7eaeb;
    cursor: pointer;
    margin-left: 0.5rem;
    padding: 0.5rem 3rem;
    border: 0.1rem solid #e7eaeb;
    border-radius: 0.5rem;
  }
}
