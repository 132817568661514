/**
 * Footer.
 *
 * @format
 */
footer {
  border-top: 1px solid #dadfe1;
}

.safety-disclamer > div {
  padding: 4rem 1.5rem 0;

  strong {
    font-family: $font-bold;
  }
}

.footer-wrapper {
  padding: 4rem 0;
  min-height: 33rem;

  @media (max-width: $max-width-lg) {
    min-height: 21rem;
  }

  @media (max-width: 767px) {
    padding: 3.6rem 0;
    min-height: 40rem;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      display: block;

      a {
        color: inherit;
      }
    }
  }
}

@import './contact/footer-contact-info';
@import './copyright/footer-copyright';
@import './legal-links/footer-legal-links';
@import './navigation/footer-navigation';
@import './social/footer-social-follow';
