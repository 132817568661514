/** @format */
@import './contact-form/contact-form';
@import './subscribe-form/subscribe-form';
@import './request-form/request-form-form';
@import './submission-response/submission-response';

.form-overlay {
  display: none;
  min-height: 100vh;
  min-width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $black;
  opacity: 0.5;
  z-index: 120;
  animation: fade-in-5;
  animation-timing-function: $ease-out-curve;
  animation-duration: $enter-screen;

  &.show {
    display: block;
  }
}

.marketo-hidden-form {
  display: none;
}

.generic-form {
  background-color: $white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  display: none;
  z-index: 121;
  border-radius: 0.4rem;
  max-width: 40rem;
  width: 90vw;
  max-height: 80vh;
  padding: 0;
  overflow: hidden;
  box-shadow: $box-shadow;

  .generic-form-inner {
    width: calc(100% + 2rem);
    overflow-y: scroll;
    margin: 0;
    padding: 4rem 8.4rem 4rem 6.4rem;
    max-height: 80vh;
    position: relative;

    @media screen and (max-width: $screen-md) {
      padding: 2.4rem 4.4rem 5.6rem 2.4rem;
    }
  }

  &.show {
    display: block;
    animation: modal-fade-in;
    animation-duration: 400ms;
    animation-timing-function: $ease-out-curve;
  }

  h2 {
    font-size: 4rem;
    letter-spacing: -0.067rem;
    margin: 0 0 2rem 0;
    line-height: 4.5rem;
    font-family: $font-semibold;
    font-weight: normal;

    @media screen and (max-width: $max-width-md) {
      font-size: 2.4rem;
      line-height: 3rem;
      letter-spacing: -0.04rem;
    }
  }

  p {
    line-height: 1.5;
    font-size: 1.8rem;
    letter-spacing: -0.023rem;
    font-family: $font-regular;
    color: $grey-dark;

    &.error-message {
      color: $error;
      font-size: 1.4rem;
      font-family: $font-semibold;
    }
  }

  button.close-form {
    position: absolute;
    top: 2rem;
    right: 6rem;
    height: 6rem;
    width: 6rem;
    padding: 0;
    font-size: 0;
    background-color: transparent;
    border: 0;
    border-radius: 0.4rem;
    z-index: 1;

    @media screen and (max-width: $max-width-md) {
      top: 0.4rem;
      right: 2.4rem;
    }

    &::before {
      content: '\f00d';
      font-family: 'Font Awesome 5 Free';
      font-size: 2rem;
      color: $blue;
      position: absolute;
      height: 6rem;
      width: 6rem;
      line-height: 6rem;
      text-align: center;
      top: 0;
      left: 0;
    }
  }

  button.btn-submit {
    margin-top: 3.6rem;
  }
}

.transitioning {
  &.form-submitted {
    .form-container {
      transition: $leave-screen $ease-out-curve;
      opacity: 0;
      display: block;
    }
  }
}

.form-submitted {
  transition: $enter-screen $ease-out-curve;
  max-height: 20rem;
  max-width: 50rem;
  margin: 2rem;

  .form-container {
    display: none;
  }
}
