/**
 * Social Follow.
 *
 * @format
 */

.social-follow {
  .social-follow-title {
    margin: 0;
    font-family: $font-semibold;
    font-size: 2rem;

    @media (max-width: $screen-lg) {
      font-size: 1.6rem;
      letter-spacing: -0.011rem;
    }
  }

  div {
    margin: 1.8rem 0;

    a {
      display: inline-block;
      padding: 0 1rem;
      font-size: 1.6rem;
      color: #99a4ae;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  @media (max-width: $screen-sm) {
    div {
      margin: 1.8rem 0 0;
    }
  }
}

.wechat-modal {
  width: 50vw;
  padding: 3rem;

  h6 {
    padding: 2rem 0;
  }

  .col {
    text-align: center;

    .img-responsive {
      margin: 0 auto;
    }
  }
}
