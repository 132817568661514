/**
 * General styles that applies for all the site.
 *
 * @format
 */

.region-content {
  a {
    font-family: $font-semibold;
  }
}

.tabledrag-toggle-weight {
  display: none;
}

.contextual.open .trigger {
  z-index: 99 !important;
}

.well {
  background-color: transparent;
  border: 0;
  box-shadow: none;
}

.modal {
  &.in {
    .modal-dialog {
      top: 50%;
      -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      -o-transform: translate(0, -50%);
      transform: translate(0, -50%);
    }
  }

  .modal-content {
    @media screen and (min-width: $screen-sm) {
      width: inherit;
    }

    .modal-padding {
      padding: 0 2rem;
    }

    .name,
    h5 {
      display: inline-block;
      font-size: $font-size-body-2;
      line-height: $line-height-body-2;
      letter-spacing: $letter-spacing-body-2;
      margin-bottom: 0.4rem;

      @media screen and (min-width: $screen-md) {
        font-family: $font-semibold;
        font-size: $font-size-h5;
        line-height: $line-height-h5;
        letter-spacing: $letter-spacing-h5;
        margin-bottom: 0.8rem;
      }
    }

    .modal-body {
      padding: 1rem 1.3rem 2.4rem;

      @media screen and (min-width: $screen-sm) {
        padding: 2rem 2.5rem 4.8rem;
      }

      @media screen and (min-width: $screen-md) {
        padding: 3.4rem 8.83% 6rem;
      }
    }

    .close {
      font-size: 3.6rem;
      position: relative;
      right: 0;
      top: -0.5rem;
      color: $blue;
      opacity: 1;
    }

    .bc-player-default_default {
      width: auto;
      position: relative;

      @media screen and (min-width: $screen-sm) {
        height: 500px;
        max-height: 100%;
      }

      .bc-player-default_default {
        width: auto;
        position: relative;

        @media screen and (min-width: $screen-sm) {
          height: 500px;
          max-height: 100%;
        }
        &.vjs-mouse {
          .vjs-big-play-button {
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            width: 6.4rem;
            height: 6.4rem;
            border: 0.2rem solid #fff;
            border-radius: 6.4rem;
            background: rgba(0, 0, 0, 0.2);

            &:before {
              font-size: 2rem;
              position: relative;
              margin: 1.9rem auto;
              width: 1.6rem;
              height: 2rem;
            }
          }
        }
      }
    }
  }
}

.popover {
  p,
  a {
    font-size: $font-size-body-2;
    line-height: $line-height-body-2;
    display: inline-block;
    vertical-align: text-bottom;
    text-align: left;
    margin: 0;
  }

  a {
    position: relative;
    top: 0.5rem;
    margin-left: 1rem;
    font-size: $font-size-body-1;
    text-shadow: none;

    &:hover,
    &:active,
    &:focus {
      color: $brand-primary;
    }
  }

  img {
    margin: 0.7rem auto;
  }
}

nav {
  &.pager-nav {
    text-align: left;
    border-top: 0.1rem solid $gray-dark;

    ul {
      margin: 1.6rem 0;

      .pager__item {
        font-size: $font-size-body-1;
        line-height: $line-height-body-1;
        color: $varian-blue;

        a {
          border: 0;
          font-family: $font-bold;
          font-size: $font-size-body-1;
          line-height: $line-height-body-1;
          padding: 0.6rem 1.5rem;
        }

        &.active {
          a {
            background-color: $gray-lighter;
            font-family: $font-light;
            color: $black;
          }
        }
      }
    }
  }
}

.bottom-overlap-spacer {
  padding-bottom: 0;

  @media screen and (min-width: $screen-sm) {
    padding-bottom: 4rem;
  }

  @media screen and (min-width: $screen-md) {
    padding-bottom: 6.4rem;
  }
}

.top-overlap-spacer {
  padding-top: 0;

  @media screen and (min-width: $screen-sm) {
    padding-top: 4rem;
  }

  @media screen and (min-width: $screen-md) {
    padding-top: 6.4rem;
  }
}

.form-item-sort-by {
  max-width: $max-width;
  width: 100%;
  position: relative;
  float: left;
  margin-bottom: 0;
  padding: 0.8rem 0;

  &:after {
    background-image: url(/themes/custom/varian/build/images/MenuClosed.svg);
    content: '';
    background-size: 100% 100%;
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    float: right;
    right: 1.4rem;
    top: 1.9rem;
    font-size: 1.2rem;
    color: $varian-blue;
    z-index: 9;
  }

  @media screen and (min-width: $screen-md) {
    border: 1px solid $varian-blue;
    padding: 0;
    width: 100%;
    max-width: $max-width;

    &:after {
      content: '\f078';
      background-image: none;
      font-family: 'Font Awesome 5 Free';
      top: 1rem;
      right: 1.4rem;
      font-weight: 900;
    }
  }

  @media screen and (min-width: $screen-md) {
    width: 49%;
    float: right;
  }

  label {
    display: none;
  }

  .form-select {
    @extend .form-item-sort-by;
    box-shadow: none;
    -webkit-appearance: none;
    padding: 0.4rem 0;
    float: left;
    max-width: $max-width;
    border-radius: 0;
    border: 0;
    color: $varian-blue;
    font-size: $font-size-body-2;
    line-height: $line-height-body-2;
    font-family: $font-bold;
    position: relative;
    z-index: 9;
    border: 0;

    @media screen and (min-width: $screen-md) {
      padding-left: 1.2rem;
    }
  }
}

.view {
  label {
    display: block;
  }
}

.row-flex {
  height: 100%;
  @media screen and (min-width: $screen-sm) {
    margin: 4rem 0;
    display: -webkit-flex;
    display: flex;
  }

  > div {
    padding: 0 1.5rem;
    min-height: 100%;
    position: relative;
  }
}

.h1-style,
.h1-style-center {
  font-size: 4rem;
  letter-spacing: -0.067rem;

  @media screen and (min-width: $screen-md) {
    font-size: 6.4rem;
    letter-spacing: -0.2rem;
  }
}

main .container .container {
  width: auto;
  padding-left: 0;
  padding-right: 0;
}
