/**
 * Helper Classes.
 *
 * Collection of reusable classes that can be used in templates.
 * @format
 */

/**
 * Hiding.
 */
.hide {
  display: none;
}

.visually-hidden {
  position: absolute;
  left: -9999rem;
  clip: rect(0 0 0 0);
  border: 0;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  margin: 0;
  padding: 0;

  &:active,
  &:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
  }
}

/**
 * Positioning.
 */
.center-vertically {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.absolute-align-top {
  position: absolute;
  top: 0;
}

.absolute-align-middle {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.absolute-align-bottom {
  position: absolute;
  bottom: 0;
}

.absolute-align-top-md {
  @media screen and (min-width: $screen-sm) {
    position: absolute;
    top: 0;
  }
}

.absolute-align-middle-md {
  @media screen and (min-width: $screen-sm) {
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.absolute-align-bottom-md {
  @media screen and (min-width: $screen-sm) {
    position: absolute;
    bottom: 0;
  }
}

.zindex-1 {
  z-index: 1;
}

.zindex-5 {
  z-index: 5;
}

.zindex-7 {
  z-index: 7;
}

.zindex-10 {
  z-index: 10;
}

.unset-zindex {
  z-index: unset;
}

/**
 * Text Alignment.
 */
.center-text,
.center-align-text {
  text-align: center !important;
}

.left-align-text {
  text-align: left;
}

.right-align-text {
  text-align: right;
}

/**
 * Spacing.
 */
.no-padding,
.nopadding {
  padding-left: 0;
  padding-right: 0;
}

.container-padding {
  padding: 0 1.5rem;
}

/**
 * Image Adjustments.
 */

.img-responsive {
  max-width: 100%;
  max-height: 100%;
}

.img-object-fit {
  object-fit: contain;
}

/**
 * Colors.
 */
.black-text {
  color: $black;
}

.white-text {
  color: $white;
}

.none,
.background-transparent,
.background-none {
  background-color: transparent;
}

.gray,
.background-gray {
  background-color: $gray-lighter;
}

.white,
.background-white {
  background-color: $white;
}

.blue,
.background-blue {
  background-color: $blue;
}

.background-purple {
  background-color: $accent-purple;
}

.background-yellow {
  background-color: $accent-yellow;
}

.background-pink {
  background-color: $accent-pink;
}
