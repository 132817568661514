/**
 * Footer Navigation.
 *
 * @format
 */

.footer-nav {
  animation: fade-in;
  animation-duration: $enter-screen;
  animation-timing-function: $standard-curve;

  & > li {
    font-size: $line-height-bold;
    line-height: 2.4rem;
    letter-spacing: -0.033rem;
    font-weight: 500;

    a {
      margin: 0 0 2rem 0;
      font-family: $font-semibold;
    }
  }

  ul ul {
    display: none;
  }

  .sub-menu {
    margin-top: 1rem;

    li {
      font-weight: normal;
      font-size: $font-size-body-2;
      line-height: 1.4;
      margin: 0.6rem 0;
      letter-spacing: -0.011rem;

      a {
        font-family: $font-regular;
      }
    }
  }

  @media (max-width: $max-width-lg) {
    & > li {
      font-size: 1.6rem;
      letter-spacing: -0.011rem;
      margin: 1rem 0;

      &:first-child {
        margin: 0 0 1rem;
      }
    }

    .sub-menu {
      display: none;
    }
  }

  @media (max-width: $screen-sm) {
    & > li:last-child {
      margin: 1rem 0 2rem;
    }
  }
}
