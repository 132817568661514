/**
 * Copyright.
 *
 * @format
 */

.copyright-wrapper {
  background-color: #e9edef;
  padding: 3.6rem 0;

  p {
    font-family: $font-regular;
    color: $black;
    font-size: 1.4rem;
    line-height: 1.5;
  }

  .copyright-year {
    font-family: $font-semibold;
  }
}
