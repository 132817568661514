/** @format */
@import './language-region/language_region';
@import './language-selector-button/language_selector_button';

.top-header-bar {
  .language-select {
    position: absolute;
    width: 14rem;
    padding: 1.2rem 0;
    background-color: $grey-light;
    right: 0;
    display: none;
    transform-origin: top left;
    z-index: 1;

    > li {
      display: block;
      position: relative;

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        transform-origin: top left;
        background-color: $grey-medium;
        height: 100%;
        width: 100%;
        transform: scaleX(0);
        opacity: 0;
        transition: $shorter-duration $sharper-curve;
      }

      &:hover {
        &::before {
          transform: scaleX(1);
          opacity: 1;
        }

        a {
          color: $white;
        }
      }

      a {
        display: block;
        padding: 0 1.2rem;
        z-index: 1;
        position: relative;
        transition: $shorter-duration $sharper-curve;
      }
    }
  }
}
