/** @format */

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in-long {
  0% {
    opacity: 0;
  }

  33% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in-5 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.5;
  }
}

@keyframes fade-out-5 {
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes slide-out {
  0% {
    opacity: 0;
    transform: scaleY(0);
  }

  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: scaleY(1);
  }

  100% {
    opacity: 1;
    transform: scaleY(0);
  }
}

@keyframes menu-fade-in {
  0% {
    opacity: 0;
    transform: translate3d(0, -1.2rem, 0);
  }

  50% {
    opacity: 0;
    transform: translate3d(0, -1.2rem, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes product-menu-open {
  0% {
    opacity: 0;
    transform: scaleX(0);
  }

  100% {
    opacity: 1;
    transform: scaleX(1);
  }
}

@keyframes product-menu-close {
  0% {
    opacity: 1;
    transform: scaleX(1);
  }

  100% {
    opacity: 0;
    transform: scaleX(0);
  }
}

@keyframes product-submenu-open-forward {
  0% {
    opacity: 0;
    transform: translate3d(0, 2rem, 0);
  }

  50% {
    opacity: 0;
    transform: translate3d(0, 2rem, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes product-submenu-open-backward {
  0% {
    opacity: 0;
    transform: translate3d(0, -2rem, 0);
  }

  50% {
    opacity: 0;
    transform: translate3d(0, -2rem, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes product-submenu-close-forward {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  50% {
    opacity: 0;
    transform: translate3d(0, 2rem, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, 2rem, 0);
  }
}

@keyframes product-submenu-close-backward {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  50% {
    opacity: 0;
    transform: translate3d(0, -2rem, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, -2rem, 0);
  }
}

@keyframes loading-spinner {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes responsive-menu-slide-out {
  0% {
    opacity: 0;
    transform: translate3d(20rem, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes responsive-menu-slide-in {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  50% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(20rem, 0, 0);
  }
}

@keyframes responsive-menu-navigate-forward {
  0% {
    opacity: 0;
    transform: translate3d(2rem, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes responsive-menu-navigate-backward {
  0% {
    opacity: 0;
    transform: translate3d(-2rem, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes modal-fade-in {
  0% {
    opacity: 0;
    transform: translate3d(-50%, calc(-50% + 4rem), 0);
  }

  50% {
    opacity: 0;
    transform: translate3d(-50%, calc(-50% + 4rem), 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(-50%, -50%, 0);
  }
}

@keyframes mobile-fade-in {
  0% {
    opacity: 0;
    transform: translate3d(-2rem, 0, 0);
  }

  50% {
    opacity: 0;
    transform: translate3d(-2rem, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes mobile-fade-out {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(-2rem, 0, 0);
  }
}
