/**
 * Base Styles.
 * @format
 */

html {
  height: 100%;
  width: 100%;
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  min-height: 100vh;
  width: 100%;
  font-family: $font-regular;
  font-size: 1.4rem;
  line-height: 1.5;
  padding: 0;
  margin: 0;
  background-color: $white;
  display: block;
}

*:focus,
*:active {
  outline: none;
}

:root {
  font-size: 62.5%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-semibold;
  font-weight: normal;
  line-height: 1.5;
}

h1 {
  line-height: $line-height-h1;

  @media screen and (max-width: $screen-sm-max) {
    font-size: $font-size-h3;
    line-height: $line-height-h3;
  }
}

h2 {
  font-size: 3.2rem;
  letter-spacing: -0.04rem;
  line-height: 1.1;

  @media screen and (min-width: $screen-lg) {
    font-size: 4.8rem;
    letter-spacing: -0.16rem;
  }
}

h3 {
  font-size: 2.4rem;
  letter-spacing: -0.04rem;
}

h4 {
  letter-spacing: $letter-spacing-h4;
  line-height: $line-height-h4;
}

h5 {
  letter-spacing: $letter-spacing-h5;
  line-height: $line-height-h5;

  @media screen and (max-width: $screen-sm-max) {
    letter-spacing: $letter-spacing-h6;
    font-size: $font-size-h6;
    line-height: $line-height-h6;
  }
}

h6 {
  letter-spacing: $letter-spacing-h6;
  line-height: $line-height-h6;
}

a {
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

p {
  font-size: 1.6rem;
  letter-spacing: -0.011rem;
}

small {
  font-size: 0.8rem;
}

strong {
  font-family: $font-bold;
}
