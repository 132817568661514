/** @format */

#request-form-button .generic-form {
  max-width: 80rem;
  text-align: left;
}

.generic-form .footnote {
  padding-top: 4rem;
}

.request-form-form {
  display: none;

  .show {
    display: block;
  }
}
