/**
 * Primary Navigation.
 *
 * @format
 */

@import './basic-menu-dropdown/basic-menu-dropdown';
@import './standard-menu-dropdown/standard-menu-dropdown';
@import './product-menu-dropdown/product-menu-dropdown';

nav.primary-navigation {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  animation: fade-in;
  animation-duration: $leave-screen;
  animation-timing-function: $ease-out-curve;

  > ul {
    list-style-type: none;
    display: flex;
    padding: 0;
    margin: 0;

    li {
      font-family: $font-regular;
      font-size: 1.8rem;
      line-height: 8rem;
      margin: 0 1.4rem;
      position: relative;

      &:last-child {
        margin-right: 1.2rem;
      }

      a {
        color: $black;

        &:hover,
        &:active,
        &:focus {
          text-decoration: none;
        }
      }

      &::before {
        content: '';
        width: 100%;
        position: absolute;
        height: 0.4rem;
        background-color: $blue;
        bottom: 0;
        left: 0;
        transform: scaleX(0);
        opacity: 0;
        transition: $enter-screen $sharper-curve;
      }

      &.active {
        .basic-menu-dropdown,
        .mega-menu-dropdown,
        .product-menu-dropdown,
        .search-dropdown {
          display: block;
          animation: slide-out;
          animation-timing-function: $sharper-curve;
          animation-duration: $shorter-duration;
          transform-origin: top left;
        }

        .mega-menu-dropdown .container,
        .product-menu-dropdown .container,
        .search-dropdown .container,
        .basic-menu-dropdown > ul {
          display: block;
          animation: menu-fade-in;
          animation-timing-function: $ease-out-curve;
          animation-duration: $complex-duration;
          transform-origin: top left;
        }

        &::before {
          transform: scaleX(1);
          opacity: 1;
        }
      }
    }
  }

  button {
    background: none;
    border: 0;
    padding: 0;
    transition: $enter-screen $ease-out-curve;

    &:hover {
      color: $hover;
    }
  }

  .fa-search {
    margin-right: 0.6rem;
  }

  .featured-menu-item .featured-link {
    line-height: 1.5;
    font-size: 1.6rem;
  }
}

nav.primary-navigation > ul li .product-menu-dropdown .product-submenu,
nav.primary-navigation > ul li .mega-menu-dropdown {
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    column-count: 3;
    column-gap: 2.4rem;
    column-fill: balance;

    li {
      margin: 0;
      padding: 0;
      font-size: 1.6rem;
      font-family: $font-semibold;
      letter-spacing: -0.011rem;
      line-height: 1.5;
      break-inside: avoid;

      a {
        color: $black;
        transition: $enter-screen $standard-curve;
        font-size: 1.6rem;
        display: block;
        padding: 0.6rem 0;

        &:hover {
          color: $hover;
        }
      }

      &::before {
        display: none;
      }

      ul {
        display: block;
        column-count: 1;
        padding-bottom: 2.4rem;

        li {
          width: 100%;
          font-family: $font-regular;

          a {
            color: #53565a;
          }
        }
      }
    }
  }
}

nav.primary-navigation > ul li .product-menu-dropdown .product-submenu {
  ul {
    column-count: 2;

    ul {
      column-count: 1;
    }
  }
}
