/**
 * Basic Menu Dropdown.
 *
 * @format
 */

nav.primary-navigation > ul li .basic-menu-dropdown {
  display: none;
  position: absolute;
  left: -3rem;
  top: 8rem;
  background-color: $white;
  padding: 3rem;
  width: 25rem;
  border: 0.1rem solid #dadfe1;
  z-index: 1;
  margin: 0;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: none;

    li {
      margin: 0;
      padding: 0;
      font-size: 1.6rem;
      font-family: $font-semibold;
      letter-spacing: -0.011rem;
      line-height: 1.5;

      a {
        color: $black;
        transition: $enter-screen $standard-curve;
        font-size: 1.6rem;
        display: block;
        padding: 1.2rem 0;

        &:hover {
          color: $hover;
        }
      }
    }

    &:first-child > a {
      padding-top: 0;
    }

    &:last-child > a {
      padding-bottom: 0;
    }

    &::before {
      display: none;
    }
  }
}
