/**
 * Fonts
 *
 * @format
 */

$font-light: 'Mark W02 Light', Arial, -apple-system, BlinkMacSystemFont,
  'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
  sans-serif;
$font-regular: 'Mark W02 Regular', Arial, -apple-system, BlinkMacSystemFont,
  'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
  sans-serif;
$font-semibold: 'Mark W02 Medium', Arial, -apple-system, BlinkMacSystemFont,
  'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
  sans-serif;
$font-bold: 'Mark W02 Bold', Arial, -apple-system, BlinkMacSystemFont,
  'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
  sans-serif;

$font-awesome: 'Font Awesome 5 Free';
$font-awesome-brands: 'Font Awesome 5 Brands';

/**
 * Body Fonts.
 */
$font-size-body-1: 1.8rem;
$font-size-body-2: 1.6rem;
$font-size-captions: 1.4rem;
$font-size-bold: 1.4rem;

/**
  * Line Heights.
  */
$line-height-h1: 6.8rem;
$line-height-h2: 5.1rem;
$line-height-h3: 4.5rem;
$line-height-h4: 4rem;
$line-height-h5: 3rem;
$line-height-h6: 2.5rem;

$line-height-body-1: 2.7rem;
$line-height-body-2: 2.4rem;
$line-height-captions: 2.1rem;
$line-height-bold: 2.1rem;

/**
  * Letter Spacings.
  */
$letter-spacing-h1: -0.2rem;
$letter-spacing-h2: -0.16rem;
$letter-spacing-h3: -0.067rem;
$letter-spacing-h4: -0.04rem;
$letter-spacing-h5: -0.04rem;
$letter-spacing-h6: -0.033rem;

$letter-spacing-body-1: -0.023rem;
$letter-spacing-body-2: -0.011rem;

/**
 * Colors
 */

$blue: #00a9e0;
$black: #000;
$white: #fff;

$grey-dark: #53565a;
$grey-medium: #99a4ae;
$grey-light: #dadfe1;
$grey-blue: #a6bbc3;

$accent-blue: #3b60af;
$accent-blue-65: #7a88bf;
$accent-blue-40: #b0b7d5;
$accent-blue-dark: #2e4b89;

$accent-purple: #682e86;
$accent-purple-65: #9e82ac;
$accent-purple-40: #c6bace;

$accent-pink: #cf0360;
$accent-pink-65: #dd6286;
$accent-pink-40: #ebb7c3;

$accent-yellow: #ffbc3e;
$accent-yellow-65: #ffcc74;
$accent-yellow-40: #ffddae;

$success: #1ac05d;
$error: #ff0402;
$hover: #3b60af;
$button-hover: #2e4b89;
$secondary-button-hover: #c4cfe7;
$disabled-color: #dadfe1;
$disabled-reverse-color: #a6bbc3;

$varian-blue: $accent-blue;
$varian-purple: $accent-purple;
$varian-pink: $accent-pink;
$varian-yellow: $accent-yellow;

/**
 * Animation Easing & Timing
 */

$enter-screen: 225ms;
$leave-screen: 195ms;
$shorter-duration: 150ms;
$longer-duration: 300ms;
$complex-duration: 375ms;

$standard-curve: cubic-bezier(0.4, 0, 0.2, 1);
$ease-out-curve: cubic-bezier(0, 0, 0.2, 1);
$acceleration-curve: cubic-bezier(0.4, 0, 1, 1);
$sharp-curve: cubic-bezier(0.4, 0, 0.6, 1);
$sharper-curve: cubic-bezier(0.23, 1, 0.32, 1);

/**
 * Box Shadows
 */

$box-shadow: 0 0.3rem 0.5rem -0.1rem rgba(0, 0, 0, 0.12),
  0 0.6rem 1rem 0 rgba(0, 0, 0, 0.08), 0 0.1rem 1.8rem 0 rgba(0, 0, 0, 0.04);

$inset-box-shadow: inset 0 0.3rem 0.3rem -0.3rem rgba(0, 0, 0, 0.2),
  inset 0 -0.3rem 0.3rem -0.3rem rgba(0, 0, 0, 0.2),
  inset 0 0.6rem 0.6rem -0.6rem rgba(0, 0, 0, 0.14),
  inset 0 -0.6rem 0.6rem -0.6rem rgba(0, 0, 0, 0.14),
  inset 0 1.8rem 1.8rem -1.8rem rgba(0, 0, 0, 0.12),
  inset 0 -1.8rem 1.8rem -1.8rem rgba(0, 0, 0, 0.12);

/**
 * Breakpoints
 */

$xs: 57.6rem;
$sm: 76.7rem;
$md: 99.2rem;
$lg: 120rem;

$max-width-sm: 767px;
$max-width-md: 1023px;
$max-width-lg: 1439px;

$max-width: 192rem;
