/** @format */

.locale-languages {
  background-color: $white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  z-index: 121;
  border-radius: 0.4rem;
  max-width: 80rem;
  width: 80vw;
  max-height: 80vh;
  padding: 0;
  overflow: hidden;
  box-shadow: $box-shadow;

  .locale-inner {
    width: calc(100% + 2rem);
    overflow-y: scroll;
    margin: 0;
    padding: 4rem 8.4rem 4rem 6.4rem;
    max-height: 80vh;
    position: relative;
  }

  &.show {
    display: block;
    animation: modal-fade-in;
    animation-duration: 400ms;
    animation-timing-function: $ease-out-curve;
  }

  h2 {
    font-size: 2.8rem;
    letter-spacing: -0.011rem;
    margin: 0 0 2rem 0;
    line-height: 1.5;
  }

  h3 {
    font-size: 2rem;
    margin-top: 0;
    letter-spacing: -0.011rem;
    line-height: 1.5;
  }

  img {
    width: 3rem;
    height: 3rem;
    margin-right: 2rem;
    vertical-align: middle;
  }

  ul {
    display: block;
    padding-left: 2rem;

    li {
      display: block;
      width: 100%;
      margin: 2rem 0;

      &:hover {
        background-color: $white;
      }

      a {
        display: block;
        width: 100%;
        font-size: 1.6rem;
        font-family: $font-semibold;
      }
    }
  }

  .region {
    margin-bottom: 4rem;
  }

  button.close-language-selector {
    position: absolute;
    top: 2rem;
    right: 6rem;
    height: 6rem;
    width: 6rem;
    padding: 0;
    font-size: 0;
    background-color: transparent;
    border: 0;
    border-radius: 0.4rem;

    &::before {
      content: '\f00d';
      font-family: 'Font Awesome 5 Free';
      font-size: 2rem;
      color: $blue;
      position: absolute;
      height: 6rem;
      width: 6rem;
      line-height: 6rem;
      text-align: center;
      top: 0;
      left: 0;
    }
  }
}

.locale-overlay {
  display: none;
  min-height: 100vh;
  min-width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $black;
  opacity: 0.5;
  z-index: 120;
  animation: fade-in-5;
  animation-timing-function: $ease-out-curve;
  animation-duration: $enter-screen;

  &.show {
    display: block;
  }
}
