/** @format */

#subscribe-form-container .generic-form {
  max-width: 80rem;
}

.generic-form .footnote {
  padding-top: 4rem;
}

.subscribe-form {
  display: none;

  .show {
    display: block;
  }
}
