/** @format */

label {
  display: block;
  font-family: $font-semibold;
  letter-spacing: -0.033rem;
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: normal;
  margin: 3.6rem 0 1rem 0;
}

input.form-control,
input,
select,
textarea {
  width: 100%;
  border: solid 0.1rem $grey-blue;
  background-color: $white;
  border-radius: 0.2rem;
  box-sizing: border-box;
  line-height: 1.5;
  padding: 1rem 1.5rem;
  transition: $enter-screen $standard-curve;
  letter-spacing: -0.011rem;
  font-size: 1.8rem;
  color: $black;

  &:focus {
    border: solid 0.2rem $accent-blue;
  }

  &::placeholder {
    color: $grey-medium;
  }

  &.error {
    border: solid 0.2rem $error;
  }
}

input.form-control,
input {
  height: 4.8rem;
}

select {
  height: 4.8rem;

  &.default-selected {
    color: $grey-medium;
  }
}

input[type='checkbox'],
input[type='radio'] {
  border: 0;
  background-color: transparent;
  position: relative;
  height: 1.6rem;
  width: 1.6rem;
  box-sizing: border-box;
  outline: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  &:focus {
    outline: none;
  }

  &::after {
    content: '\f00c';
    transition: $enter-screen $standard-curve;
    font-family: $font-awesome;
    color: $white;
    text-align: center;
    border: solid 0.1rem $accent-blue;
    border-radius: 0.2rem;
    height: 1.6rem;
    width: 1.6rem;
    position: relative;
    display: block;
    font-size: 1.1rem;
    line-height: 1.6rem;
    background-color: $white;
    cursor: pointer;
  }

  &:checked {
    background-color: $accent-blue;

    &::after {
      background-color: $accent-blue;
    }
  }
}

input[type='radio'] {
  border-radius: 50%;

  &::after {
    content: '\f111';
    transition: $enter-screen $standard-curve;
    text-align: center;
    border: solid 0.1rem $accent-blue;
    border-radius: 50%;
    height: 1.6rem;
    width: 1.6rem;
    position: relative;
    display: block;
    font-size: 0.6rem;
    line-height: 1.6rem;
  }
}

.error-message {
  font-size: 1.4rem;
  font-family: $font-semibold;
  color: $error;
  margin: 1rem 0;
  animation: fade-in;
  animation-timing-function: $ease-out-curve;
  animation-duration: $enter-screen;

  i {
    margin-right: 0.6rem;
  }
}
