/**
 * Product Menu Dropdown.
 *
 * @format
 */

nav.primary-navigation > ul li .product-menu-dropdown {
  display: none;
  background: linear-gradient(to right, #a6bbc3 50%, $white 50%);
  position: fixed;
  left: 0;
  width: 100%;
  border: 0.1rem solid #dadfe1;
  margin: 0;

  button {
    &:hover {
      color: $black;
    }
  }

  .product-menu-wrapper {
    background: linear-gradient(to right, #a6bbc3 22.5rem, $white 0);
    position: relative;
    transform: rotate(0deg);
    z-index: 2;
    margin: 0 auto;
    max-width: 117rem;
  }

  .container {
    display: none;
  }

  .product-menu {
    height: 40rem;
    width: 22.5rem;
    position: relative;
    transition: $leave-screen $ease-out-curve;

    &.tid-74 {
      height: 48rem;
    }

    &.tid-78 {
      height: 43rem;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 2.4rem 0;
      position: relative;

      li {
        font-family: $font-semibold;
        font-size: 1.6rem;
        line-height: 1.5;
        letter-spacing: -0.011rem;
        color: $black;
        display: block;
        background: none;
        margin: 0;
        padding: 0;
        position: static;

        button {
          display: block;
          z-index: 1;
          position: relative;
          padding: 1rem 0;
          width: 100%;
          text-align: left;

          span {
            position: relative;
            z-index: 1;
          }

          &::before {
            display: block;
            content: '';
            position: absolute;
            height: 100%;
            width: calc(100% + 3.6rem);
            left: auto;
            right: 0;
            top: 0;
            opacity: 1;
            background-color: $white;
            transform: scaleX(0);
            transform-origin: top right;
          }
        }

        &.active {
          .product-submenu {
            display: block;
          }

          button {
            &::before {
              display: block;
              content: '';
              position: absolute;
              height: 100%;
              width: calc(100% + 3.6rem);
              left: auto;
              right: 0;
              top: 0;
              opacity: 1;
              background-color: $white;
              transform: scaleX(1);
              transform-origin: top right;
            }
          }
        }

        &.closing {
          button {
            &::before {
              display: block;
              content: '';
              position: absolute;
              height: 100%;
              width: calc(100% + 3.6rem);
              left: auto;
              right: 0;
              top: 0;
              opacity: 0;
              background-color: $white;
              transform: scaleX(0);
              transform-origin: top right;
            }
          }
        }

        &.transitioning {
          &.active {
            button {
              &::before {
                animation: product-menu-open;
                animation-duration: $longer-duration;
                animation-timing-function: $ease-out-curve;
              }
            }

            &.forward .product-submenu {
              animation: product-submenu-open-forward;
              animation-duration: $complex-duration;
              animation-timing-function: $ease-out-curve;
            }

            &.backward .product-submenu {
              animation: product-submenu-open-backward;
              animation-duration: $complex-duration;
              animation-timing-function: $ease-out-curve;
            }
          }

          &.closing {
            button {
              &::before {
                animation: product-menu-close;
                animation-duration: $enter-screen;
                animation-timing-function: $ease-out-curve;
              }
            }

            &.forward .product-submenu {
              animation: product-submenu-close-forward;
              animation-duration: $complex-duration;
              animation-timing-function: $ease-out-curve;
            }

            &.backward .product-submenu {
              animation: product-submenu-close-backward;
              animation-duration: $complex-duration;
              animation-timing-function: $ease-out-curve;
            }
          }
        }

        &::before {
          display: none;
        }
      }
    }
  }

  .product-submenu {
    display: none;
    position: absolute;
    width: calc(117rem * 0.75);
    top: 0.6rem;
    left: calc(117rem * 0.25);
    padding: 0;
    transition: $enter-screen $shorter-duration;
    height: 30rem;
  }
}
